import './sass/main.scss';

function sendForm() {
  const form = document.getElementById('js-contact-form');

  form.addEventListener('submit', e => {
    e.preventDefault();

    const payload = {};
    const fields = form.querySelectorAll('.contact__input');

    fields.forEach(field => payload[field.name] = field.value);

    console.log('=> payload', payload);

    //fetch('/mail.php', payload)
  });
}

function headerMenu() {
  const bar = document.getElementById('js-header-bar');

  bar.addEventListener('click', (e) => {
    if (e.target.classList.contains('not-active')) {
      e.target.classList.add('active');
      e.target.classList.remove('not-active');
    } else {
      e.target.classList.remove('active');
      e.target.classList.add('not-active');
    }

    const menu = document.getElementById('js-header-list');
    menu.classList.toggle('active');
  });
}

function scrollToSection() {
  const links = document.querySelectorAll('.js-header-link');

  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();

      const { classList, dataset: { id } } = e.target;

      links.forEach((el) => el.classList.remove('active'));
      classList.add('active');

      const section = document.getElementById(id);
      const rect = section.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const top = rect.top + scrollTop;

      window.scrollTo({
        top: top - 90,
        behavior: "smooth"
      });
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  sendForm();
  headerMenu();
  scrollToSection();
});
